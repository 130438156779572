import { FC } from 'react';
import styles from './Footer.module.scss';
import Header from '../header/Header';
import FooterBanner from '@/components/ui/banners/footer-banner/FooterBanner';

interface IFooter {
  isAuthActive: boolean;
  isDefaultPopularSlider?: boolean;
  setAuthActive: (isAuthActive: boolean) => void;
}

const Footer: FC<IFooter> = ({ isAuthActive, setAuthActive, isDefaultPopularSlider }) => {
  return (
    <footer className={`${styles.footer}`}>
      <FooterBanner />
      <Header isAuthActive={isAuthActive} setAuthActive={setAuthActive} isBottom={true} />
    </footer>
  );
};

export default Footer;
