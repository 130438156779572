import { FC } from 'react';
import { useState } from 'react';
import Link from 'next/link';
import { ISideBarData } from '@/interfaces/sidebar.interface';
import styles from './Sidebar.module.scss';

const Sidebar: FC<ISideBarData> = ({ sidebarGenres }) => {
  const [isSuperGenres, setIsSuperGenres] = useState(false);

  return (
    <div className={styles.sidebarWrap}>
      <div className={`titleH2 ${styles.sidebar__title}`}>Популярные жанры:</div>
      <div className={isSuperGenres ? `${styles.sidebar} ${styles.sidebar_active}` : `${styles.sidebar}`} data-scrollbar="sidebar">
        <ul className={styles.sidebarList}>
          <li className={`${styles.sidebarList__item} ${styles.sidebarList__item_title}`}>
            <span>Популярные жанры</span>
          </li>

          {sidebarGenres && sidebarGenres.length && (
            <>
              {sidebarGenres.slice(0, 13).map((genre) => (
                <li key={genre.id} className={styles.sidebarList__item}>
                  <Link prefetch={false} href={`/zhanr/${genre.alias}/`}>
                    {genre.title}
                  </Link>
                </li>
              ))}

              <li onClick={() => setIsSuperGenres(!isSuperGenres)} className={isSuperGenres ? `${styles.sidebarList__item} ${styles.sidebarList__item_toggle} ${styles.active}` : `${styles.sidebarList__item} ${styles.sidebarList__item_toggle}`}>
                Еще жанры
              </li>
            </>
          )}

          <ul className={styles.sidebarList__hiddenGenres}>
            {sidebarGenres &&
              sidebarGenres.length &&
              sidebarGenres.slice(13, 50).map((genre) => (
                <li key={genre.id} className={styles.sidebarList__item}>
                  <Link prefetch={false} href={`/zhanr/${genre.alias}/`}>
                    {genre.title}
                  </Link>
                </li>
              ))}
          </ul>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
